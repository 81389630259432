import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../context/authContext";
import {
  Box,
  Typography,
  Container,
  useTheme,
  Grid,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  Avatar,
  Divider,
  Tabs,
  Tab,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";
import { PieChart, LineChart, BarChart, Gauge, gaugeClasses, pieArcLabelClasses } from "@mui/x-charts";
import Topbar from "../global/TopBar";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import TokenOutlinedIcon from "@mui/icons-material/TokenOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import GeocodeFetcher from "../global/temp2";
import TimeDifferenceCalculator from "../global/temp";

const Dashboard = () => {
  const navigate = useNavigate();
  const [activityData, setActivityData] = useState([]);
  const [managementData, setManagementData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [delayData, setDelayData] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);
  const [bankText, setBankText] = useState("");
  const [pieChartData, setPieChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [value, setValue] = useState(0);

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const { currentUser } = useContext(AuthContext);

  const theme = useTheme();

  // Function to extract unique years from activity data
  const generateAvailableYearOptions = () => {
    const uniqueYears = new Set(activityData.map((activity) => activity.date.slice(6, 10))); // Extract year
    return Array.from(uniqueYears).map((year) => ({
      value: year,
      label: year,
    }));
  };

  // Function to extract unique months from activity data for the selected year
  const generateAvailableMonthOptions = () => {
    if (!selectedYear) return [];
    const uniqueMonths = new Set(
      activityData.filter((activity) => activity.date.slice(6, 10) === selectedYear).map((activity) => activity.date.slice(3, 5))
    ); // Extract month
    return Array.from(uniqueMonths).map((month) => {
      const date = new Date(`${selectedYear}-${month}-01`);
      return {
        value: month,
        label: date.toLocaleString("default", { month: "long" }),
      };
    });
  };

  const getColor = (value) => {
    if (value > 0) return "green";
    if (value < 0) return "red";
    return "inherit"; // Default color (black or current theme color)
  };

  const formatValue = (value) => {
    if (value > 0) return `+${value}`;
    return `${value}`;
  };

  const checkActivity = async () => {
    setError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_CALL}activity/${currentUser.id}`, config);
      console.log(response.data.data);

      if (response.data.success === 1) {
        const response2 = await axios.get(`${process.env.REACT_APP_API_CALL}history/${currentUser.id}`, config);
        console.log(response2.data.data);

        const activityData = response.data.data;
        const historyData = response2.data.data;

        const mergedData = activityData.map((activity) => {
          // Find all history items with the same date as the current activity
          const relatedHistory = historyData.filter((hist) => hist.date === activity.date);

          // Create an object to hold the additional types
          const historyTypes = relatedHistory.reduce((acc, hist) => {
            acc[hist.type] = hist.value;
            return acc;
          }, {});

          // Merge the activity data with the new history types
          return {
            ...activity,
            ...historyTypes, // Adding new type-value pairs to the activity object
          };
        });

        setActivityData(mergedData);
        console.log("Merged Data:", mergedData);
      }
    } catch (error) {
      setError(error);
    }
  };

  const checkHistory = async () => {
    setError(null);
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `history/${currentUser.id}`, config);
      setActivityData(response.data.data);
      console.log("Activity Data:", response.data.data); // Add this console log
    } catch (error) {
      setError(error);
    }
  };

  const checkManagementInfo = async () => {
    setError(null);
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `management/${currentUser.id}`, config);
      setManagementData(response.data.data);
    } catch (error) {
      setError(error);
    }
  };

  const checkInventory = async () => {
    setError(null);
    try {
      const response = await axios.get(process.env.REACT_APP_API_CALL + `inventory/${currentUser.id}`, config);
      setInventoryData(response.data.data[0]);
      console.log("Inventory:", response.data.data[0]); // Add this console log
      console.log(inventoryData);
    } catch (error) {
      setError(error);
    }
  };

  // // Calculate time spent on each task category by month
  // const calculateTimeSpentByCategory = () => {
  //   if (!selectedMonth) return [];

  //   // Filter activityData based on the selected month
  //   const filteredData = activityData.filter((activity) => {
  //     const activityMonth = activity.date.slice(3, 5); // Extract month
  //     return activityMonth === selectedMonth;
  //   });

  //   // Initialize an object to store time spent for each task category
  //   const timeSpentByCategory = {};

  //   // Iterate through filtered data and accumulate time spent for each category
  //   filteredData.forEach((activity) => {
  //     if (!activity.report || activity.report === "null") return;

  //     if (Array.isArray(activity.report)) {
  //       activity.report.forEach((task) => {
  //         if (!timeSpentByCategory[task.title]) {
  //           timeSpentByCategory[task.title] = 0;
  //         }
  //         timeSpentByCategory[task.title] += task.timeSpent;
  //       });
  //     }
  //   });

  //   // Convert time spent data into the format expected by PieChart component
  //   const pieChartData = Object.entries(timeSpentByCategory).map(([category, timeSpent]) => ({
  //     id: category,
  //     value: timeSpent,
  //     label: category,
  //   }));

  //   return pieChartData;
  // };

  // Calculate average time spent on each task category per day for the selected month
  const calculateTimeSpentByCategory = () => {
    if (!selectedMonth) return [];

    // Filter activityData based on the selected month
    const filteredData = activityData.filter((activity) => {
      const activityMonth = activity.date.slice(3, 5); // Extract month
      return activityMonth === selectedMonth;
    });

    // Initialize an object to store time spent and count for each task category
    const timeSpentByCategory = {};

    // Iterate through filtered data and accumulate time spent and count for each category
    filteredData.forEach((activity) => {
      if (!activity.report || activity.report === "null") return;

      if (Array.isArray(activity.report)) {
        activity.report.forEach((task) => {
          if (!timeSpentByCategory[task.title]) {
            timeSpentByCategory[task.title] = {
              totalTime: 0,
              totalCount: 0,
            };
          }
          timeSpentByCategory[task.title].totalTime += task.timeSpent;
          timeSpentByCategory[task.title].totalCount++;
        });
      }
    });

    // Calculate average time spent per day for each category
    const pieChartData = Object.entries(timeSpentByCategory).map(([category, { totalTime, totalCount }]) => {
      const averageTimeSpentPerDay = totalCount > 0 ? totalTime / totalCount : 0;
      return {
        id: category,
        value: averageTimeSpentPerDay,
        label: category,
      };
    });

    return pieChartData;
  };

  // Call calculateTimeSpentByCategory whenever selectedMonth changes
  useEffect(() => {
    const newData = calculateTimeSpentByCategory();
    setPieChartData(newData);
  }, [selectedMonth, activityData]);

  const filterData = () => {
    const filteredData = activityData.filter((item) => {
      const dateParts = item.date.split("-");
      const itemMonth = dateParts[1];
      return itemMonth === selectedMonth;
    });
    calculateDelays(filteredData);
  };

  const calculateDelays = (filtered) => {
    const delays = filtered.map((activity) => {
      const workType = activity.work_type;
      const managementInfo = managementData.find((m) => m.work_type === workType);

      if (managementInfo) {
        const entranceTimeParts = managementInfo.entrance_time.split(":");
        const entranceTimeMs = parseInt(entranceTimeParts[0]) * 60 * 60 * 1000 + parseInt(entranceTimeParts[1]) * 60 * 1000;

        // Convert start_hour to milliseconds (assuming it's a Unix timestamp)
        const startTimeMs = parseInt(activity.start_hour);

        const actualStartTimeDate = new Date(startTimeMs);
        const actualStartHourMinutes = actualStartTimeDate.getHours() * 60;
        const actualStartMinutes = actualStartHourMinutes + actualStartTimeDate.getMinutes();

        // console.log("Actual Start Time:", actualStartTimeDate);
        // console.log("Actual Start Time in MS:", startTimeMs);
        // console.log("Actual Start Time in Minutes:", actualStartMinutes);

        const delayMs = actualStartMinutes - entranceTimeMs / (60 * 1000); // Convert entranceTimeMs to minutes

        const delayMinutes = delayMs; // Keep delay in minutes

        // Extract only the day from the date and convert it to a number
        const day = parseInt(activity.date.split("-")[0]);

        // console.log("Activity Date:", activity.date);
        // console.log("Entrance Time in MS:", entranceTimeMs);
        // console.log("Actual Start Time in MS:", actualStartMinutes);
        // console.log("Delay in MS:", delayMs);
        // console.log("Delay in Minutes:", delayMinutes);

        return {
          day: day,
          delayMinutes: delayMinutes,
        };
      }

      return {
        day: activity.date.split("-")[0],
        delayMinutes: 0,
      };
    });

    const minDelay = Math.min(...delays.map((d) => d.delayMinutes));
    const maxDelay = Math.max(...delays.map((d) => d.delayMinutes));

    const minY = Math.min(minDelay, 0); // Include zero as the center
    const maxY = Math.max(maxDelay, 0);

    const yRange = [minY, maxY];

    const aggregatedDelays = delays.reduce((acc, curr) => {
      acc[curr.day] = (acc[curr.day] || 0) + curr.delayMinutes;
      return acc;
    }, {});

    const delayDataFormatted = Object.keys(aggregatedDelays).map((day) => ({
      day: parseInt(day), // Convert day back to number
      delayMinutes: aggregatedDelays[day],
    }));

    setDelayData(delayDataFormatted);

    // Check if excess minutes are negative
    if (inventoryData.bank < 0) {
      // Calculate absolute value of excess minutes
      const absoluteExcessMinutes = Math.abs(inventoryData.bank);

      // Round absolute excess minutes up to nearest multiple of 15
      const roundedExcessMinutes = Math.ceil(absoluteExcessMinutes / 15) * 15;

      const bankTextHours = Math.floor(roundedExcessMinutes / 60);
      const bankTextMinutes = roundedExcessMinutes % 60;

      console.log(bankTextHours, bankTextMinutes);

      // Construct bank text for negative excess minutes
      if (bankTextHours === 1 && bankTextMinutes > 0) {
        setBankText(`${bankTextHours}h e ${bankTextMinutes}m`);
      } else if (bankTextHours === 1 && bankTextMinutes === 0) {
        setBankText(`${bankTextHours} hora`);
      } else if (bankTextHours === 0 && bankTextMinutes > 0) {
        setBankText(`${bankTextMinutes} minutos`);
      } else if (bankTextHours > 0 && bankTextMinutes === 0) {
        setBankText(`${bankTextHours} horas`);
      } else if (bankTextHours > 0 && bankTextMinutes > 0) {
        setBankText(`${bankTextHours}h e ${bankTextMinutes}m`);
      } else if (bankTextHours === 0 && bankTextMinutes === 0) {
        setBankText("0");
      } else {
        setBankText("Não tem banco de horas.");
      }
    } else {
      // Calculate excess minutes in intervals of 15
      const excessMinutesInIntervals = Math.floor(inventoryData.bank / 15) * 15;
      const bankTextHours = Math.floor(excessMinutesInIntervals / 60);
      const bankTextMinutes = excessMinutesInIntervals % 60;

      console.log(bankTextHours, bankTextMinutes);

      // Construct bank text for positive excess minutes
      if (bankTextHours === 1 && bankTextMinutes > 0) {
        setBankText(`${bankTextHours}h e ${bankTextMinutes}m`);
      } else if (bankTextHours === 1 && bankTextMinutes === 0) {
        setBankText(`${bankTextHours} hora`);
      } else if (bankTextHours === 0 && bankTextMinutes > 0) {
        setBankText(`${bankTextMinutes} minutos`);
      } else if (bankTextHours > 0 && bankTextMinutes > 0) {
        setBankText(`${bankTextHours}h e ${bankTextMinutes}m`);
      } else if (bankTextHours > 0 && bankTextMinutes === 0) {
        setBankText(`${bankTextHours} horas`);
      } else if (bankTextHours === 0 && bankTextMinutes === 0) {
        setBankText("0");
      } else {
        setBankText("Não tem banco de horas.");
      }
    }
  };

  // Calculate performance rating based on delay data for the selected month
  const calculatePerformanceRating = () => {
    let goodDays = 0;
    let mediumDays = 0;
    let badDays = 0;

    delayData.forEach((data) => {
      const delayMinutes = data.delayMinutes;

      if (delayMinutes <= 0) {
        goodDays++;
      } else if (delayMinutes <= 15) {
        mediumDays++;
      } else {
        badDays++;
      }
    });

    // Calculate performance rating based on the counts of good, medium, and bad days
    const maxRating = goodDays * 100 + mediumDays * 75 - badDays * 50; // Maximum possible rating

    // Calculate the total possible rating based on the counts of good, medium, and bad days
    const totalPossibleRating = (goodDays + mediumDays + badDays) * 100;

    // Calculate the normalized rating
    let rating = maxRating / totalPossibleRating;

    // Normalize the rating to ensure it falls within the range of 0 to 100
    rating = Math.max(0, Math.min(100, rating * 100));

    // Round up the rating to the nearest whole number
    rating = Math.ceil(rating);

    return rating;
  };

  // Call calculatePerformanceRating to get the gauge value
  const gaugeValue = calculatePerformanceRating();

  const getColorForValue = (value) => {
    // Convert the hex colors to RGB format
    const redStart = parseInt("D2", 16);
    const greenStart = parseInt("22", 16);
    const blueStart = parseInt("2D", 16);

    const redMiddle = parseInt("FF", 16);
    const greenMiddle = parseInt("BF", 16);
    const blueMiddle = 0;

    const redEnd = parseInt("23", 16);
    const greenEnd = parseInt("88", 16);
    const blueEnd = parseInt("23", 16);

    let red, green, blue;

    if (value <= 50) {
      // Interpolate between start and middle colors for values from 0 to 50
      const ratio = value / 50;
      red = Math.round(redStart + (redMiddle - redStart) * ratio);
      green = Math.round(greenStart + (greenMiddle - greenStart) * ratio);
      blue = Math.round(blueStart + (blueMiddle - blueStart) * ratio);
    } else {
      // Interpolate between middle and end colors for values from 50 to 100
      const ratio = (value - 50) / 50;
      red = Math.round(redMiddle + (redEnd - redMiddle) * ratio);
      green = Math.round(greenMiddle + (greenEnd - greenMiddle) * ratio);
      blue = Math.round(blueMiddle + (blueEnd - blueMiddle) * ratio);
    }

    // Convert the interpolated RGB values back to hex format
    const colorHex = "#" + ((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1);

    return colorHex;
  };

  // Assuming activityData is already parsed from JSON
  const filteredTableData = activityData
    .filter((activity) => {
      const parts = activity.date.split("-");
      const month = parts[1]; // "06"
      const year = parts[2]; // "2024"
      return year === selectedYear && month === selectedMonth;
    })
    .sort((a, b) => {
      // Parse dates in "DD-MM-YYYY" format
      const [dayA, monthA, yearA] = a.date.split("-");
      const [dayB, monthB, yearB] = b.date.split("-");

      // Create Date objects for comparison
      const dateA = new Date(`${yearA}-${monthA}-${dayA}`);
      const dateB = new Date(`${yearB}-${monthB}-${dayB}`);

      return dateA - dateB; // Sort ascending by date
    });

  console.log(filteredTableData);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedMonth("");
    setSelectedYear("");
  };

  // Call filterData whenever selectedEmployeeId or selectedMonth changes
  useEffect(() => {
    filterData();
  }, [selectedYear, selectedMonth, activityData, managementData]);

  useEffect(() => {
    if (currentUser && currentUser.email) {
      checkInventory();
      checkActivity();
      checkManagementInfo();
    } else {
      navigate("/login");
    }
  }, [currentUser]);

  useEffect(() => {
    // Initialize selectedYear and selectedMonth to current year and month when activityData is fetched
    if (activityData.length > 0) {
      const currentYear = new Date().getFullYear().toString();
      const currentMonth = new Date().toISOString().slice(5, 7); // Format: "MM"
      setSelectedYear(currentYear);
      setSelectedMonth(currentMonth);
      filterData(); // Ensure filter runs after setting the initial selected year and month
    }
  }, [activityData]);

  useEffect(() => {
    // setLoading(true)

    if (
      activityData.length > 0 &&
      managementData.length > 0 &&
      delayData.length > 0 &&
      inventoryData && // Check if inventoryData is not null or undefined
      Object.keys(inventoryData).length > 0 && // Check if inventoryData has properties
      bankText !== ""
    ) {
      console.log("cenas");
      setLoading(false);
    }
  }, [activityData, managementData, delayData, inventoryData]);

  if (loading) {
    return (
      <>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }

  return (
    <>
      <Topbar />
      {/* <GeocodeFetcher />
      <TimeDifferenceCalculator /> */}
      <Container component="main" maxWidth="xs">
        <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
          {error ? (
            <Typography variant="h6" color="error">
              Error: {error.message}
            </Typography>
          ) : (
            <>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <br></br>
                  <Avatar sx={{ margin: "auto", bgcolor: "secondary.main" }}>
                    <DashboardOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h2" fontWeight={"bold"}>
                    Dashboard
                  </Typography>
                  <br></br>
                </Grid>

                <Grid item xs={6}>
                  <Grid
                    container
                    rowSpacing={1}
                    sx={{ mb: 4, fontWeight: "bold", textAlign: "center", bgcolor: "background.paper", borderRadius: 2 }}
                  >
                    <Grid item xs={3}>
                      <Avatar
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: 1,
                          bgcolor: "brown",
                          width: 45,
                          height: 45,
                        }}
                      >
                        <AccountBalanceWalletOutlinedIcon />
                      </Avatar>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography component="h1" variant="h4" sx={{ fontWeight: "bold", textAlign: "center" }}>
                        Banco Horas
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {inventoryData.bank < 0 ? (
                        <>
                          <Typography component="h1" variant="h4" sx={{ mb: 1, fontWeight: "bold", textAlign: "center", color: "red" }}>
                            -{bankText}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography component="h1" variant="h4" sx={{ mb: 1, fontWeight: "bold", textAlign: "center", color: "green" }}>
                            {bankText}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Grid
                    container
                    rowSpacing={1}
                    sx={{ mb: 4, fontWeight: "bold", textAlign: "center", bgcolor: "background.paper", borderRadius: 2 }}
                  >
                    <Grid item xs={3}>
                      <Avatar
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: 1,
                          bgcolor: "brown",
                          width: 45,
                          height: 45,
                        }}
                      >
                        <TokenOutlinedIcon />
                      </Avatar>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        component="h1"
                        variant="h4"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: 2,
                          fontWeight: "bold",
                          textAlign: "center",
                          borderRadius: 2,
                        }}
                      >
                        Tokens
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {inventoryData.tokens <= 0 ? (
                        <>
                          <Typography component="h1" variant="h4" sx={{ mb: 1, fontWeight: "bold", textAlign: "center", color: "red" }}>
                            {inventoryData.tokens}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography component="h1" variant="h4" sx={{ mb: 1, fontWeight: "bold", textAlign: "center", color: "green" }}>
                            {inventoryData.tokens}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} textAlign="center">
                  <Typography variant="h2" fontWeight={"bold"}>
                    Escolha uma das opções
                  </Typography>
                  <br></br>
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ bgcolor: "black", fontWeight: "bold" }} />
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons={false}
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                  >
                    <Tab icon={<AssessmentOutlinedIcon />} label="Os Meus Gráficos" value={1} />
                    <Tab icon={<TableChartOutlinedIcon />} label="As Minhas Tabelas" value={2} />
                  </Tabs>
                </Grid>

                {value === 1 && (
                  <>
                    <Grid item xs={12}>
                      <br></br>
                      <Avatar sx={{ margin: "auto", bgcolor: "secondary.main" }}>
                        <AssessmentOutlinedIcon />
                      </Avatar>
                      <Typography component="h1" variant="h2" fontWeight={"bold"}>
                        Os Meus Gráficos
                      </Typography>
                      <br></br>
                    </Grid>

                    {/* Dropdown for selecting year */}
                    <Grid item xs={6}>
                      <Grid
                        container
                        rowSpacing={1}
                        sx={{ mb: 4, fontWeight: "bold", textAlign: "center", bgcolor: "background.paper", borderRadius: 2 }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            component="h1"
                            variant="h4"
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: 2,
                              marginLeft: 2,
                              marginRight: 2,
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Escolha o ano:
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Select
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                            sx={{ mb: 2, fontWeight: "bold", textAlign: "center", borderRadius: 2 }}
                          >
                            {generateAvailableYearOptions().map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>

                    {selectedYear !== "" && (
                      <>
                        {/* Dropdown for selecting month */}
                        <Grid item xs={6}>
                          <Grid
                            container
                            rowSpacing={1}
                            sx={{ mb: 4, fontWeight: "bold", textAlign: "center", bgcolor: "background.paper", borderRadius: 2 }}
                          >
                            <Grid item xs={12}>
                              <Typography
                                component="h1"
                                variant="h4"
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginBottom: 2,
                                  marginLeft: 2,
                                  marginRight: 2,
                                  fontWeight: "bold",
                                  textAlign: "center",
                                }}
                              >
                                Escolha o mês:
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Select
                                value={selectedMonth}
                                onChange={(e) => setSelectedMonth(e.target.value)}
                                sx={{ mb: 2, fontWeight: "bold", textAlign: "center", borderRadius: 2 }}
                              >
                                {generateAvailableMonthOptions().map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {selectedMonth !== "" && selectedYear !== "" && (
                      <>
                        <Grid item xs={12} md={6}>
                          <br></br>
                          <Typography component="h1" variant="h4" sx={{ mb: 1, fontWeight: "bold", textAlign: "center" }}>
                            Performance Mensal
                          </Typography>
                          <Box display="flex" flexDirection="column" alignItems="center">
                            <Gauge
                              width={250}
                              height={100}
                              value={gaugeValue}
                              valueMin={0}
                              valueMax={100}
                              startAngle={-90}
                              endAngle={90}
                              cornerRadius="75%"
                              innerRadius="70%"
                              outerRadius="100%"
                              sx={(theme) => ({
                                fontWeight: "bold",
                                textAlign: "center",
                                bgcolor: "background.paper",
                                borderRadius: 2,
                                [`& .${gaugeClasses.valueText}`]: {
                                  fontSize: 40,
                                  transform: "translate(0px, -20px)",
                                  fontWeight: "bold",
                                },
                                [`& .${gaugeClasses.valueArc}`]: {
                                  fill: getColorForValue(gaugeValue), // Use the function to determine color
                                },
                                [`& .${gaugeClasses.referenceArc}`]: {
                                  fill: theme.palette.text.disabled,
                                },
                              })}
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={6} mt={5}>
                          <Typography component="h1" variant="h4" sx={{ mb: 4, fontWeight: "bold", textAlign: "center" }}>
                            Assiduidade
                          </Typography>
                          <BarChart
                            series={[{ data: delayData.map((d) => d.delayMinutes) }]}
                            height={290}
                            width={400}
                            xAxis={[{ data: delayData.map((d) => d.day), scaleType: "band", label: "Dias do Mês" }]}
                            yAxis={[
                              {
                                type: "number",
                                colorMap: {
                                  type: "piecewise",
                                  thresholds: [1, 15], // Define thresholds for color mapping
                                  colors: ["green", "yellow", "red"], // Define colors corresponding to thresholds
                                },
                                label: "Minutos em atraso",
                                labelStyle: { transform: "rotate(-90, -28, 125)" }, // Rotate and adjust the offset here
                              },
                            ]} // Specify the domain for the y-axis
                            margin={{ top: 10, left: 40, right: 10 }}
                            sx={{ fontWeight: "bold", textAlign: "center", bgcolor: "background.paper", borderRadius: 2 }}
                          />
                        </Grid>

                        {pieChartData.length !== 0 && (
                          <Grid item xs={12} md={6}>
                            <Typography component="h1" variant="h4" sx={{ mt: 4, fontWeight: "bold", textAlign: "center" }}>
                              Média Tarefas Mês (min.)
                            </Typography>
                            <Box display="flex" flexDirection="column" alignItems="center">
                              <PieChart
                                margin={{ bottom: 30 }}
                                series={[
                                  {
                                    arcLabel: (item) => `${item.value}`,
                                    arcLabelMinAngle: 45,
                                    data: pieChartData,
                                    innerRadius: 30,
                                    outerRadius: 100,
                                    paddingAngle: 3,
                                    cornerRadius: 3,
                                    startAngle: 0,
                                    endAngle: 360,
                                    cx: 200,
                                    cy: 120, // Adjusted to move the chart upwards
                                  },
                                ]}
                                height={400} // Increased height to provide more space for the legend
                                width={400}
                                slotProps={{
                                  legend: {
                                    direction: "row",
                                    position: { vertical: "bottom", horizontal: "middle" },
                                    padding: 5, // Adjust padding as needed
                                  },
                                }}
                                sx={{
                                  [`& .${pieArcLabelClasses.root}`]: {
                                    fill: "white",
                                    fontWeight: "bold",
                                  },
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  bgcolor: "background.paper",
                                  borderRadius: 2,
                                }}
                              />
                            </Box>
                          </Grid>
                        )}

                        {/* <Grid item xs={12} md={6}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <LineChart
                      xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                      series={[
                        {
                          data: [2, 5.5, 2, 8.5, 1.5, 5],
                        },
                      ]}
                      width={350}
                      height={300}
                    />
                  </Box>
                </Grid> */}
                      </>
                    )}
                  </>
                )}

                {value === 2 && (
                  <>
                    <Grid item xs={12}>
                      <br></br>
                      <Avatar sx={{ margin: "auto", bgcolor: "secondary.main" }}>
                        <TableChartOutlinedIcon />
                      </Avatar>
                      <Typography component="h1" variant="h2" fontWeight={"bold"}>
                        As Minhas Tabelas
                      </Typography>
                      <br></br>
                    </Grid>

                    {/* Dropdown for selecting year */}
                    <Grid item xs={6}>
                      <Grid
                        container
                        rowSpacing={1}
                        sx={{ mb: 4, fontWeight: "bold", textAlign: "center", bgcolor: "background.paper", borderRadius: 2 }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            component="h1"
                            variant="h4"
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: 2,
                              marginLeft: 2,
                              marginRight: 2,
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Escolha o ano:
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Select
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                            sx={{ mb: 2, fontWeight: "bold", textAlign: "center", borderRadius: 2 }}
                          >
                            {generateAvailableYearOptions().map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>

                    {selectedYear !== "" && (
                      <>
                        {/* Dropdown for selecting month */}
                        <Grid item xs={6}>
                          <Grid
                            container
                            rowSpacing={1}
                            sx={{ mb: 4, fontWeight: "bold", textAlign: "center", bgcolor: "background.paper", borderRadius: 2 }}
                          >
                            <Grid item xs={12}>
                              <Typography
                                component="h1"
                                variant="h4"
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginBottom: 2,
                                  marginLeft: 2,
                                  marginRight: 2,
                                  fontWeight: "bold",
                                  textAlign: "center",
                                }}
                              >
                                Escolha o mês:
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Select
                                value={selectedMonth}
                                onChange={(e) => setSelectedMonth(e.target.value)}
                                sx={{ mb: 2, fontWeight: "bold", textAlign: "center", borderRadius: 2 }}
                              >
                                {generateAvailableMonthOptions().map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {selectedMonth !== "" && selectedYear !== "" && (
                      <>
                        <Grid item xs={12} md={6}>
                          <br></br>
                          <Typography component="h1" variant="h4" sx={{ mb: 1, fontWeight: "bold", textAlign: "center" }}>
                            Registos Mensais
                          </Typography>
                          <Box sx={{ overflow: "auto" }}>
                            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell style={{ fontSize: "1rem" }}>Dia</TableCell>
                                    <TableCell style={{ fontSize: "1rem" }}>Tipo Trabalho</TableCell>
                                    <TableCell style={{ fontSize: "1rem" }}>Hora Entrada</TableCell>
                                    <TableCell style={{ fontSize: "1rem" }}>Hora Saída</TableCell>
                                    <TableCell style={{ fontSize: "1rem" }}>Tempo Almoço</TableCell>
                                    <TableCell style={{ fontSize: "1rem" }}>Tempo Saída</TableCell>
                                    <TableCell style={{ fontSize: "1rem" }}>Tempo Médico</TableCell>
                                    <TableCell style={{ fontSize: "1rem" }}>Local Entrada</TableCell>
                                    <TableCell style={{ fontSize: "1rem" }}>Local Saída</TableCell>
                                    <TableCell style={{ fontSize: "1rem" }}>Dif. Tokens</TableCell>
                                    <TableCell style={{ fontSize: "1rem" }}>Dif. Minutos</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {filteredTableData.map((activity) => {
                                    const tokenValue = (Number(activity.entry_token) || 0) + (Number(activity.exit_token) || 0);
                                    const bankValue = Number(activity.exit_bank) || 0;

                                    return (
                                      <TableRow key={activity.id}>
                                        <TableCell style={{ fontSize: "1rem" }}>{activity.date.split("-")[0]}</TableCell>
                                        <TableCell style={{ fontSize: "1rem" }}>{activity.work_type}</TableCell>
                                        <TableCell style={{ fontSize: "1rem" }}>
                                          {new Date(Number(activity.start_hour)).toLocaleTimeString()}
                                        </TableCell>
                                        <TableCell style={{ fontSize: "1rem" }}>
                                          {activity.end_hour ? new Date(Number(activity.end_hour)).toLocaleTimeString() : "---"}
                                        </TableCell>
                                        <TableCell style={{ fontSize: "1rem" }}>
                                          {activity.lunch_time ? activity.lunch_time + " min." : "---"}
                                        </TableCell>
                                        <TableCell style={{ fontSize: "1rem" }}>
                                          {activity.leave_time ? activity.leave_time + " min." : "---"}
                                        </TableCell>
                                        <TableCell style={{ fontSize: "1rem" }}>
                                          {activity.doctor_time ? activity.doctor_time + " min." : "---"}
                                        </TableCell>
                                        <TableCell style={{ fontSize: "1rem" }}>
                                          {activity.start_location ? activity.start_location : "---"}
                                        </TableCell>
                                        <TableCell style={{ fontSize: "1rem" }}>
                                          {activity.end_location ? activity.end_location : "---"}
                                        </TableCell>
                                        <TableCell style={{ fontSize: "1rem", color: getColor(tokenValue) }}>
                                          {formatValue(tokenValue)}
                                        </TableCell>
                                        <TableCell style={{ fontSize: "1rem", color: getColor(bankValue) }}>
                                          {formatValue(bankValue)} min.
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </Box>
                          </Box>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </>
          )}
          <br></br>
        </Box>
      </Container>
    </>
  );
};

export default Dashboard;
